import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Switch,
  Checkbox,
  Divider,
  Popover,
  Layout,
  Tabs,
  Button,
  Space,
  InputNumber,
  Dropdown,
  Menu,
  Row,
  Col,
  message,
} from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import {
  ModalScreen,
  Spacer,
  Text,
  PositionController,
  Title,
  ActionButtonGroup,
  CloseButton,
  Icon,
} from "../styles/commonStyle";
import { commonTheme } from "../styles/theme";
// import "../styles/CreateAndModifyCouponModal.css";
import SelectProductMenu from "./SelectProductMenu";
import {
  createCouponAction,
  getCouponAction,
  modifyCouponAction,
} from "../reducers/coupon";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "./CustomButton";

import "../styles/MessageSetting.css";
import { padding } from "@mui/system";
import ScheduleTypeData from "./ScheduleTypeData";
import MessageInfoNoticeModal from "./MessageInfoNoticeModal";
import NoticeModal from "./NoticeModal";
import NumberTypeData from "./NumberTypeData";
import {
  createAutoMessageAction,
  getAutoMessageSettingAction,
  modifyAutoMessageSettigAction,
} from "../reducers/message";
import {
  validateAutoMessageName,
  validateBody,
  validateTitle,
} from "./Validator";
import { getCoupon } from "../api/couponAPI";
import AutomaticMessageNameLabel from "./AutomaticMessageNameLabel";
const { TabPane } = Tabs;
const customerRealtiveOptionList = [
  {
    id: 1,
    label: "고객 추가 시",
    function_type: "NEW_CUSTOMER_CREATED",
    related: "customer",
    send_time_type: "IMMEDIATELY",
  },
  {
    id: 2,
    label: "이용권 신규 등록 시",
    function_type: "CUSTOMER_FIRST_BUY_TICKET_ALARM",
    related: "customer",
    send_time_type: "IMMEDIATELY",
  },
  {
    id: 3,
    label: "대여권 구매 시",
    function_type: "CUSTOMER_BUY_RENTAL_TICKET_ALARM",
    related: "customer",
    send_time_type: "IMMEDIATELY",
  },
  {
    id: 4,
    label: "계약 완료 시",
    function_type: "CUSTOMER_CONTRACT_DONE_ALARM",
    related: "customer",
    send_time_type: "IMMEDIATELY",
  },
  {
    id: 5,
    label: "생일자",
    function_type: "CUSTOMER_BIRTHDAY_ALARM",
    related: "customer",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 6,
    label: "이용권 재등록 시",
    function_type: "CUSTOMER_REBUY_TICKET_ALARM",
    related: "customer",
    send_time_type: "IMMEDIATELY",
  },
  {
    id: 7,
  },
  {
    id: 8,
    label: "장기 미출석",
    function_type: "CUSTOMER_NON_ATTENDANCE",
    related: "customer",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 9,
    label: "쿠폰 만료 안내",
    function_type: "CUSTOMER_COUPON_EXPIRE_ALARM",
    related: "customer",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 10,
    label: "활성 회원에게(1회발송)",
    function_type: "ACTIVE_CUSTOMER_ALARM",
    related: "customer",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 11,
  },
  {
    id: 12,
    label: "장기 미수업 (수강권) (준비중)",
    function_type: "LESSON_NON",
    disabled: true,
    related: "customer",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 13,
  },
  {
    id: 14,
    label: "비활성 회원에게(1회발송)",
    function_type: "INACTIVE_CUSTOMER_ALARM",
    related: "customer",
    send_time_type: "RELATIVELY_TIME",
  },
];

const productRelativeOptionList = [
  {
    id: 1,
    label: "회원권 만료전",
    function_type: "MEMBER_TICKET_EXPIRE_ALARM",
    related: "product",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 2,
    label: "락커 만료",
    function_type: "LOCKER_EXPIRE_ALARM",
    // disabled: true,
    related: "product",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 3,
    label: "상품 홀딩 시",
    function_type: "CUSTOMER_HOLDING_NEW_ALARM",
    related: "product",
    send_time_type: "IMMEDIATELY",
  },
  {
    id: 4,
    label: "상품 연장 시 (준비중)",
    function_type: "PRODUCT_EXTENSION",
    disabled: true,
    related: "product",
    send_time_type: "IMMEDIATELY",
  },
  {
    id: 5,
    label: "수강권 만료전",
    function_type: "LESSON_TICKET_EXPIRE_ALARM",
    related: "product",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 6,
    label: "운동복 만료",
    function_type: "SPORTS_EXPIRE_ALARM",
    // disabled: true,
    related: "product",
    send_time_type: "RELATIVELY_TIME",
  },

  {
    id: 7,
    label: "홀딩 종료 임박",
    function_type: "CUSTOMER_HOLDING_EXPIRE_ALARM",
    related: "product",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 8,
    label: "상품 양도 시 (준비중)",
    function_type: "PRODUCT_ASSIGNMENT",
    disabled: true,
    related: "product",
    send_time_type: "IMMEDIATELY",
  },
  {
    id: 9,
    label: "회원권 만료 시",
    function_type: "MEMBER_TICKET_EXPIRE_DATE_ALARM",
    related: "product",
    send_time_type: "RELATIVELY_TIME",
  },
  {
    id: 10,
  },
  {
    id: 11,
  },
  {
    id: 12,
  },
  {
    id: 13,
    label: "수강권 만료 시",
    function_type: "LESSON_TICKET_EXPIRE_DATE_ALARM",
    related: "product",
    send_time_type: "RELATIVELY_TIME",
  },
];

const AdInfoPopoverContent = () => {
  return (
    <div>
      <Title color="#000" style={{ fontSize: "12px", lineHeight: "17.38px" }}>
        광고성 정보에 대한 판단 기준
      </Title>
      <Spacer size={6} />
      <Text size="12px" color="#000" style={{ display: "block" }}>
        1. 툭가/할인 상품 안내
      </Text>
      <Text size="12px" color="#000" style={{ display: "block" }}>
        2. 상품 및 서비스 홍보를 위한 프로모션/이벤트 또는 발신인의 이미지 홍보
      </Text>
      <Text size="12px" color="#000" style={{ display: "block" }}>
        3. 주된 정보가 아니더라도 부수적으로 위 [1,2]의 내용을 포함한 경우
      </Text>
      <Spacer size={3} />
      <Text size="12px" color="#A4A4A4" style={{ display: "block" }}>
        * 운톡 push 알림인 경우에는 해당되지 않습니다.
      </Text>
    </div>
  );
};

const AutoInputPopoverContent = () => {
  return (
    <div>
      <Title color="#000" style={{ fontSize: "12px", lineHeight: "17.38px" }}>
        자동 입력 방법 예시
      </Title>
      <Spacer size={6} />
      <Text size="12px" color="#000" style={{ display: "block" }}>
        안녕하세요{" "}
        <Text fontWeight={700} size="12px" color="#FA943D">
          #센터명#
        </Text>
        입니다.
      </Text>
      <Text size="12px" color="#000" style={{ display: "block" }}>
        <Text fontWeight={700} size="12px" color="#FA943D">
          #보유 회원권명#
        </Text>
        의 만료일이{" "}
        <Text fontWeight={700} size="12px" color="#FA943D">
          #전송기준#
        </Text>
        남으셨습니다.
      </Text>
      <Text size="12px" color="#000" style={{ display: "block" }}>
        재등록 시 문의 부탁드립니다. 감사합니다 :)
      </Text>
      <Spacer size={6} />
      <Text
        fontWeight={700}
        size="12px"
        color="#EB5252"
        style={{ display: "block" }}
      >
        *주의*
      </Text>
      <Text size="12px" color="#000" style={{ display: "block" }}>
        자동입력문구에 따라 <ins>글자수가 추가적으로 발생할 수 있습니다.</ins>
      </Text>
    </div>
  );
};

const MessageSettingModal = ({
  closeSetAddModal,
  jgroupData,
  selectedNumber,
  selectedData,
  isModify,
}) => {
  // console.log("selectedData", selectedData);
  // console.log("jgroupData", jgroupData);
  const dispatch = useDispatch();
  // const couponList = useSelector((state) => state.couponReducer.couponList);
  const [couponList, setCouponList] = useState([]);
  const createAutoMessageDone = useSelector(
    (state) => state.messageReducer.createAutoMessageDone
  );
  const modifyAutoMessageDone = useSelector(
    (state) => state.messageReducer.modifyAutoMessageDone
  );
  const [isSelectOption, setIsSelectOption] = useState(() => {
    if (isModify && selectedData) {
      return false;
    } else {
      return true;
    }
  });
  const [isCustomerNonAttendanceModal, setIsCustomerNonAttendanceModal] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(() => {
    if (isModify && selectedData) {
      let newOptionList = [
        ...customerRealtiveOptionList,
        ...productRelativeOptionList,
      ];

      let _newOptionList = newOptionList.filter(
        (data) => data.function_type === selectedData.function_type
      );
      // console.log("!!", _newOptionList);

      return {
        label: `${_newOptionList[0].label}`,
        function_type: `${selectedData.function_type}`,
        related: `${selectedData.related}`,
        send_time_type: `${
          selectedData.entry_list[0].send_time_type === "IMMEDIATELY"
            ? "IMMEDIATELY"
            : "RELATIVELY_TIME"
        }`,
      };
    } else {
      return {
        label: "",
        function_type: "",
        related: "",
        send_time_type: "",
      };
    }
  });
  const [autoMessageName, setAutoMessageName] = useState(() => {
    if (isModify && selectedData) {
      return `${selectedData.name}`;
    } else {
      return "";
    }
  });
  const [immediatelyType, setImmediatelyType] = useState(() => {
    if (isModify && selectedData) {
      if (selectedData.entry_list[0].send_time_type === "IMMEDIATELY") {
        return true;
      }
    } else {
      return false;
    }
  });
  const [scheduleType, setScheduleType] = useState(() => {
    if (isModify && selectedData) {
      let _checkedScheduleType = selectedData.entry_list.some(
        (data) => data.send_time_type === "RELATIVELY_TIME"
      );
      if (_checkedScheduleType) {
        return true;
      }
    } else {
      return false;
    }
  });
  const [numberType, setNumberType] = useState(() => {
    if (isModify && selectedData) {
      let _checkedNumberType = selectedData.entry_list.some(
        (data) => data.send_time_type === "RELATIVELY_COUNT"
      );
      if (_checkedNumberType) {
        return true;
      }
    } else {
      return false;
    }
  });
  const [isInfoClicked, setIsInfoClicked] = useState(false);
  /**일정 기준 추가 시 사용되는 배열*/
  const [scheduleTypeList, setScheduleTypeList] = useState(() => {
    if (isModify && selectedData) {
      let _scheduleTypeList = selectedData.entry_list
        .filter((data) => data.send_time_type === "RELATIVELY_TIME")
        .map((data) => {
          return {
            dateCount: data.unit_count,
            dateCountType: data.entry_unit,
            dateCountTiming: data.entry_type,
            dateCountTimingType: data.hour > 12 ? "AFTERNOON" : "MORNING",
            sendHour:
              data.hour === 24
                ? 12
                : data.hour > 12
                ? data.hour - 12
                : data.hour,
            sendMinute: data.minute,
          };
        });
      return _scheduleTypeList;
    } else {
      return [];
    }
  });
  const [cursorPosition, setCursorPosition] = useState(null);

  /**횟수 기준 추가시 사용되는 배열 state */
  const [numberTypeList, setNumberTypeList] = useState(() => {
    if (isModify && selectedData) {
      let _numberTypeList = selectedData.entry_list
        .filter((data) => data.send_time_type === "RELATIVELY_COUNT")
        .map((data) => {
          return {
            count: data.unit_count,
          };
        });
      return _numberTypeList;
    } else {
      return [];
    }
  });
  const [autoMessageType, setAutoMessageType] = useState(() => {
    if (isModify && selectedData) {
      if (
        selectedData.auto_message_type_list.some((data) => data === "SMS") &&
        selectedData.auto_message_type_list.some((data) => data === "PUSH")
      ) {
        return {
          type: "DOUBLE",
          label: "문자메시지 + 운톡 푸쉬 알림",
          labelText: "문자와 운톡푸쉬알림을 동시에 전송합니다.",
        };
      }
      if (selectedData.auto_message_type_list.some((data) => data === "SMS")) {
        return {
          type: "SMS",
          label: "문자메시지",
          labelText: "문자메시지로 알림을 전송합니다.",
        };
      } else if (
        selectedData.auto_message_type_list.some((data) => data === "PUSH")
      ) {
        return {
          type: "PUSH",
          label: "운톡 푸쉬 알림",
          labelText: "운톡 어플로 알림을 전송합니다.",
        };
      } else if (
        selectedData.auto_message_type_list.some(
          (data) => data === "SMART_PUSH"
        )
      ) {
        return {
          type: "SMART_PUSH",
          label: "스마트 전송",
          labelText:
            "운톡가입이 되어있는 고객에게는 운톡푸쉬 알림으로, 그 외 고객에게는 문자메시지로 전송합니다.",
        };
      }
    } else {
      return {};
    }
  });
  const [selectCoupon, setSelectCoupon] = useState({});
  const [isAdInformation, setIsAdInformation] = useState(() => {
    if (isModify && selectedData && selectedData.has_advertisement) {
      return true;
    } else {
      return false;
    }
  });
  const [messageTitle, setMessageTitle] = useState(() => {
    if (isModify && selectedData) {
      if (selectedData.has_advertisement) {
        return "(광고)" + selectedData.title;
      } else {
        return selectedData.title;
      }
    } else {
      return "";
    }
  });
  const [messageBody, setMessageBody] = useState(() => {
    if (isModify && selectedData) {
      return selectedData.body;
    } else {
      return "";
    }
  });
  const [autoInputWordList, setAutoInputWordList] = useState(() => {
    if (isModify && selectedData) {
      let _autoInputWordList = [
        {
          dataType: "CENTER_NAME",
          label: "#센터명#",
          previewText: `${jgroupData.jgroup_name}`,
        },
        {
          dataType: "MEMBER_NAME",
          label: "#회원명#",
          previewText: "홍길동",
        },
        {
          dataType: "ONTALK_LINK",
          label: "#운톡가입링크#",
          previewText: `센터의 원활한 사용을 위한 운톡 어플링트를 함께 보내드립니다!(링크)`,
        },
      ];
      let _newAutoInputWordList;
      switch (selectedData.function_type) {
        case "NEW_CUSTOMER_CREATED":
        case "CUSTOMER_BIRTHDAY_ALARM":
        case "CUSTOMER_NON_ATTENDANCE":
        case "ACTIVE_CUSTOMER_ALARM":
        case "INACTIVE_CUSTOMER_ALARM":
          return _autoInputWordList;
          break;
        case "MEMBER_TICKET_EXPIRE_ALARM":
        case "LESSON_TICKET_EXPIRE_ALARM":
          _newAutoInputWordList = [
            {
              dataType: "PRODUCT_NAME",
              label: "#상품명#",
              previewText: `'상품명'`,
            },
            {
              dataType: "SEND_TYPE",
              label: "#전송기준#",
              previewText: "",
            },
            {
              dataType: "PAYMENT_HISTORY",
              label: "#결제내역#",
              previewText: `상품명 -원\n----------------------\n총결제 금액 -원`,
            },
            {
              dataType: "EXPIRE_DATE",
              label: "#만료일#",
            },
          ];
          return [..._autoInputWordList, ..._newAutoInputWordList];
          break;
        case "LESSON_TICKET_EXPIRE_DATE_ALARM":
        case "MEMBER_TICKET_EXPIRE_DATE_ALARM":
          _newAutoInputWordList = [
            {
              dataType: "PRODUCT_NAME",
              label: "#상품명#",
              previewText: `'상품명'`,
            },
            {
              dataType: "SEND_TYPE",
              label: "#전송기준#",
              previewText: "",
            },
            {
              dataType: "PAYMENT_HISTORY",
              label: "#결제내역#",
              previewText: `상품명 -원\n----------------------\n총결제 금액 -원`,
            },
          ];
          return [..._autoInputWordList, ..._newAutoInputWordList];
          break;
        case "CUSTOMER_FIRST_BUY_TICKET_ALARM":
        case "CUSTOMER_BUY_RENTAL_TICKET_ALARM":
        case "CUSTOMER_REBUY_TICKET_ALARM":
          _newAutoInputWordList = [
            {
              dataType: "PRODUCT_NAME",
              label: "#상품명#",
              previewText: `'상품명'`,
            },

            {
              dataType: "PAYMENT_HISTORY",
              label: "#결제내역#",
              previewText: `상품명 -원\n----------------------\n총결제 금액 -원`,
            },
          ];
          return [..._autoInputWordList, ..._newAutoInputWordList];
          break;
        case "SPORTS_EXPIRE_ALARM":
        case "LOCKER_EXPIRE_ALARM":
          _newAutoInputWordList = [
            {
              dataType: "PRODUCT_NAME",
              label: "#상품명#",
              previewText: `'상품명'`,
            },
            {
              dataType: "SEND_TYPE",
              label: "#전송기준#",
              previewText: "",
            },
            {
              dataType: "EXPIRE_DATE",
              label: "#만료일#",
            },
          ];
          return [..._autoInputWordList, ..._newAutoInputWordList];
          break;

        case "CUSTOMER_HOLDING_NEW_ALARM":
        case "CUSTOMER_HOLDING_EXPIRE_ALARM":
          _newAutoInputWordList = [
            {
              dataType: "PRODUCT_NAME",
              label: "#상품명#",
              previewText: `'상품명'`,
            },
            {
              dataType: "SEND_TYPE",
              label: "#전송기준#",
              previewText: "",
            },
            {
              dataType: "HOLDING_START",
              label: "#홀딩시작일#",
              previewText: `xxxx년 xx월 xx일`,
            },
            {
              dataType: "HOLDING_END",
              label: "#홀딩종료일#",
              previewText: `xxxx년 xx월 xx일`,
            },
          ];
          return [..._autoInputWordList, ..._newAutoInputWordList];

          break;
        case "CUSTOMER_COUPON_EXPIRE_ALARM":
          _newAutoInputWordList = [
            {
              dataType: "COUPON_NAME",
              label: "#쿠폰명#",
              previewText: `쿠폰명`,
            },
            {
              dataType: "SEND_TYPE",
              label: "#전송기준#",
              previewText: "",
            },
          ];
          return [..._autoInputWordList, ..._newAutoInputWordList];
          break;

        case "CUSTOMER_CONTRACT_DONE_ALARM":
          _newAutoInputWordList = [
            {
              dataType: "CONTRACT_NAME",
              label: "#계약서명#",
              previewText: `샘플 계약서`,
            },
            {
              dataType: "CONTRACT_LINK",
              label: "#계약서링크#",
              previewText: `계약서 링크입니다.(링크)`,
            },
          ];

          return [..._autoInputWordList, ..._newAutoInputWordList];
          break;

        default:
          break;
      }
    } else {
      return [];
    }
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [currentMessageType, setCurrentMessageType] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewText, setPreviewText] = useState("");
  const [finished, setFinished] = useState(false);
  const [isCreateCompleteModal, setIsCreateCompleteModal] = useState(false);
  const [isModifyCompleteModal, setIsModifyCompleteModal] = useState(false);
  const [cancelModify, setCancelModify] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isActive, setIsActive] = useState(() => {
    if (isModify && selectedData) {
      if (selectedData.status === "DISABLED") {
        return false;
      } else {
        return true;
      }
    }
  });
  const adInfoRef = useRef();
  const formRef = useRef();
  const autoInputWordRef = useRef();
  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );
  const [byteValue, setByteValue] = useState(0);

  useEffect(() => {
    async function setCoupons() {
      const coupons = await getCoupon({
        jgroup_key: jgroupKey,
      });

      // 자동 문자 설정 추가 시 수량이 무제한인 경우와 V2인 쿠폰만 가능하도록 filter
      let _couponList = coupons.filter(
        (coupon) => coupon.version === "V2" && !coupon.jgcp_limited
      );
      _couponList.unshift({
        jgcp_key: -1,
        jgcp_name: "선택 없음",
      });
      // console.log("_couponList", _couponList);
      setCouponList([..._couponList]);
    }

    setCoupons();
  }, []);

  useEffect(() => {
    if (
      couponList &&
      couponList.length > 0 &&
      isModify &&
      selectedData.coupon_parent_key
    ) {
      let _label;
      _label =
        couponList &&
        couponList.length > 0 &&
        couponList.filter(
          (data) => data.jgcp_key === selectedData.coupon_parent_key
        );
      setSelectCoupon({
        label: _label.length > 0 ? _label[0].jgcp_name : "",
        coupon_key: selectedData.coupon_parent_key,
      });
    }
  }, [couponList]);

  useEffect(() => {
    const titleByte = getByteLength(messageTitle);
    const bodyByte = getByteLength(messageBody);

    if (titleByte + bodyByte > 90) {
      setCurrentMessageType("LMS");
    } else {
      setCurrentMessageType("SMS");
    }
    if (isAdInformation) {
      const jgroupNameByte = getByteLength(jgroupData?.jgroup_name);
      setByteValue(titleByte + bodyByte + jgroupNameByte + 27);
    } else {
      setByteValue(titleByte + bodyByte);
    }
  }, [messageTitle, messageBody]);

  // useEffect(() => {
  //   if (selectCoupon.coupon_key > 0) {
  //     setIsAdInformation(true);
  //   }
  // }, [selectCoupon]);

  useEffect(() => {
    if (createAutoMessageDone && finished) {
      setIsCreateCompleteModal(true);
    } else if (modifyAutoMessageDone && finished) {
      setIsModifyCompleteModal(true);
    }
  }, [createAutoMessageDone, modifyAutoMessageDone]);

  useEffect(() => {
    let autoInputWordContainer = autoInputWordRef.current;
    if (autoInputWordContainer) {
      const handleMouseEnter = () => {
        formRef.current.style.overflow = "hidden";
      };
      const handleMouseLeave = () => {
        formRef.current.style.overflow = "auto";
      };
      autoInputWordContainer.addEventListener("mouseenter", handleMouseEnter);
      autoInputWordContainer.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        autoInputWordContainer.removeEventListener(
          "mouseenter",
          handleMouseEnter
        );
        autoInputWordContainer.removeEventListener(
          "mouseleave",
          handleMouseLeave
        );
      };
    }
  });

  /**전송기준, 광고관련 state 변경시 미리보기 변경 되어야함  */
  useEffect(() => {
    let newPreviewTitleText;
    let newPreviewBodyText;
    if (isAdInformation && isPreview) {
      newPreviewTitleText = "(광고)" + previewTitle;
      setPreviewTitle(newPreviewTitleText);
    } else if (!isAdInformation && isPreview) {
      newPreviewTitleText = previewTitle.replace("(광고)", "");
      setPreviewTitle(newPreviewTitleText);
    }

    newPreviewTitleText = handleOnchangePreview(messageTitle);
    newPreviewBodyText = handleOnchangePreview(messageBody);
    setPreviewTitle(newPreviewTitleText);
    setPreviewText(newPreviewBodyText);
  }, [
    scheduleType,
    scheduleTypeList,
    numberType,
    numberTypeList,
    isAdInformation,
  ]);

  /** 미리보기 handler 함수 */
  const handleOnchangePreview = (replaceText) => {
    let scheduleTypeText = "";
    let numberTypeText = "";
    if (scheduleTypeList.length > 0 && scheduleTypeList) {
      scheduleTypeText = `${
        scheduleTypeList[0].dateCount === 0 ||
        scheduleTypeList[0].dateCountTiming === "EQUAL"
          ? ""
          : scheduleTypeList[0].dateCount
      }${
        scheduleTypeList[0].dateCountTiming === "EQUAL"
          ? ""
          : scheduleTypeList[0].dateCountType === "DAY"
          ? "일"
          : "주"
      } ${
        scheduleTypeList[0].dateCountTiming === "EQUAL" ? "당일" : ""
        // : scheduleTypeList[0].dateCountTiming === "PLUS"
        // ? "후"
        // : "전"
      }`;
    }
    if (numberTypeList && numberTypeList.length > 0) {
      numberTypeText = `${numberTypeList[0].count}회`;
    }
    return replaceText
      .replace(
        /#센터명#/g,
        `${jgroupData.jgroup_name && jgroupData.jgroup_name}`
      )
      .replace(/#회원명#/g, `${"홍길동"}`)
      .replace(/#운톡가입링크#/g, `http://link.broj.co.kr/connect/XXXXXX`)
      .replace(/#상품명#/g, "'상품명'")
      .replace(
        /#전송기준#/g,
        scheduleType && numberType
          ? `${scheduleTypeText}/${numberTypeText}`
          : scheduleType
          ? `${scheduleTypeText}`
          : `${numberTypeText}`
      )
      .replace(
        /#결제내역#/g,
        "상품명 -원\n---------------------------------\n총결제 금액 -원"
      )
      .replace(/#홀딩시작일#/g, "xxxx년 xx월 xx일")
      .replace(/#홀딩종료일#/g, "xxxx년 xx월 xx일")
      .replace(/#쿠폰명#/g, "쿠폰명")
      .replace(/#계약서명#/g, "샘플 계약서")
      .replace(/#계약서링크#/g, "계약서 링크입니다.\n(링크)")
      .replace(/#만료일#/g, "2023.04.20");
  };

  /** 현재 focus된 input이 title인지 body인지 */
  const handleFocusInput = (focus) => {
    setFocusedInput(focus);
  };

  /**옵션 dropdown 열고 닫는 함수 */
  const handleIsSelectOption = () => {
    if (isSelectOption) {
      setIsSelectOption(false);
      return;
    }
    setIsSelectOption(true);
  };

  const handleSetCancel = () => {
    setCancelModify((cancelModify) => !cancelModify);
  };

  const setAutoInputWordListData = (value) => {
    let _autoInputWordList = [
      {
        dataType: "CENTER_NAME",
        label: "#센터명#",
        previewText: `${jgroupData.jgroup_name}`,
      },
      {
        dataType: "MEMBER_NAME",
        label: "#회원명#",
        previewText: "홍길동",
      },
      {
        dataType: "ONTALK_LINK",
        label: "#운톡가입링크#",
        previewText: `센터의 원활한 사용을 위한 운톡 어플링트를 함께 보내드립니다!(링크)`,
      },
    ];
    let _newAutoInputWordList;
    switch (value.function_type) {
      case "NEW_CUSTOMER_CREATED":
      case "CUSTOMER_BIRTHDAY_ALARM":
      case "CUSTOMER_NON_ATTENDANCE":
      case "ACTIVE_CUSTOMER_ALARM":
      case "INACTIVE_CUSTOMER_ALARM":
        setAutoInputWordList([..._autoInputWordList]);
        break;
      case "MEMBER_TICKET_EXPIRE_ALARM":
      case "LESSON_TICKET_EXPIRE_ALARM":
        _newAutoInputWordList = [
          {
            dataType: "PRODUCT_NAME",
            label: "#상품명#",
            previewText: `'상품명'`,
          },
          {
            dataType: "SEND_TYPE",
            label: "#전송기준#",
            previewText: "",
          },
          {
            dataType: "PAYMENT_HISTORY",
            label: "#결제내역#",
            previewText: `상품명 -원\n----------------------\n총결제 금액 -원`,
          },
          {
            dataType: "EXPIRE_DATE",
            label: "#만료일#",
          },
        ];
        setAutoInputWordList([..._autoInputWordList, ..._newAutoInputWordList]);
        break;
      case "MEMBER_TICKET_EXPIRE_DATE_ALARM":
      case "LESSON_TICKET_EXPIRE_DATE_ALARM":
        _newAutoInputWordList = [
          {
            dataType: "PRODUCT_NAME",
            label: "#상품명#",
            previewText: `'상품명'`,
          },
          {
            dataType: "SEND_TYPE",
            label: "#전송기준#",
            previewText: "",
          },
          {
            dataType: "PAYMENT_HISTORY",
            label: "#결제내역#",
            previewText: `상품명 -원\n----------------------\n총결제 금액 -원`,
          },
        ];
        setAutoInputWordList([..._autoInputWordList, ..._newAutoInputWordList]);
        break;
      case "CUSTOMER_FIRST_BUY_TICKET_ALARM":
      case "CUSTOMER_BUY_RENTAL_TICKET_ALARM":
      case "CUSTOMER_REBUY_TICKET_ALARM":
        _newAutoInputWordList = [
          {
            dataType: "PRODUCT_NAME",
            label: "#상품명#",
            previewText: `'상품명'`,
          },

          {
            dataType: "PAYMENT_HISTORY",
            label: "#결제내역#",
            previewText: `상품명 -원\n----------------------\n총결제 금액 -원`,
          },
        ];
        setAutoInputWordList([..._autoInputWordList, ..._newAutoInputWordList]);
        break;
      case "SPORTS_EXPIRE_ALARM":
      case "LOCKER_EXPIRE_ALARM":
        _newAutoInputWordList = [
          {
            dataType: "PRODUCT_NAME",
            label: "#상품명#",
            previewText: `'상품명'`,
          },
          {
            dataType: "SEND_TYPE",
            label: "#전송기준#",
            previewText: "",
          },
          {
            dataType: "EXPIRE_DATE",
            label: "#만료일#",
          },
        ];
        setAutoInputWordList([..._autoInputWordList, ..._newAutoInputWordList]);
        break;

      case "CUSTOMER_HOLDING_NEW_ALARM":
      case "CUSTOMER_HOLDING_EXPIRE_ALARM":
        _newAutoInputWordList = [
          {
            dataType: "PRODUCT_NAME",
            label: "#상품명#",
            previewText: `'상품명'`,
          },
          {
            dataType: "SEND_TYPE",
            label: "#전송기준#",
            previewText: "",
          },
          {
            dataType: "HOLDING_START",
            label: "#홀딩시작일#",
            previewText: `xxxx년 xx월 xx일`,
          },
          {
            dataType: "HOLDING_END",
            label: "#홀딩종료일#",
            previewText: `xxxx년 xx월 xx일`,
          },
        ];
        setAutoInputWordList([..._autoInputWordList, ..._newAutoInputWordList]);
        break;
      case "CUSTOMER_COUPON_EXPIRE_ALARM":
        _newAutoInputWordList = [
          {
            dataType: "COUPON_NAME",
            label: "#쿠폰명#",
            previewText: `쿠폰명`,
          },
          {
            dataType: "SEND_TYPE",
            label: "#전송기준#",
            previewText: "",
          },
        ];
        setAutoInputWordList([..._autoInputWordList, ..._newAutoInputWordList]);
        break;

      case "CUSTOMER_CONTRACT_DONE_ALARM":
        _newAutoInputWordList = [
          {
            dataType: "CONTRACT_NAME",
            label: "#계약서명#",
            previewText: `샘플 계약서`,
          },
          {
            dataType: "CONTRACT_LINK",
            label: "#계약서링크#",
            previewText: `계약서 링크입니다.(링크)`,
          },
        ];
        setAutoInputWordList([..._autoInputWordList, ..._newAutoInputWordList]);
        break;

      default:
        break;
    }
  };

  /**옵션 선택시 onclick 핸들러 함수 */
  const handleSelectOptionBtn = (value) => {
    // console.log("value", value);
    let _selectedOption = {
      label: value.label,
      function_type: value.function_type,
      related: value.related,
      send_time_type: value.send_time_type,
    };

    let _scheduleTypeList = {
      dateCount: 0,
      dateCountType: "",
      dateCountTiming: "",
      dateCountTimingType: "",
      sendHour: "",
      sendMinute: "",
    };

    let _numberTypeList = {
      count: 0,
    };

    let _autoMessageType = {
      type: "SMART_PUSH",
      label: "스마트 전송",
      labelText:
        "운톡가입이 되어있는 고객에게는 운톡푸쉬알림으로, 그 외 고객에게는 문자메시지로 전송합니다.",
    };
    setSelectedOption({ ..._selectedOption });
    setAutoMessageName(value.label);

    setAutoMessageType({ ..._autoMessageType });
    // setMessageTitle("안녕하세요 #센터명# 입니다.");

    if (value.send_time_type === "IMMEDIATELY") {
      setImmediatelyType(true);
      setScheduleType(false);
      setNumberType(false);
      setIsSelectOption(false);
    } else if (value.send_time_type === "RELATIVELY_TIME") {
      if (value.function_type === "LESSON_TICKET_EXPIRE_ALARM") {
        setNumberType(true);
        setScheduleType(false);
        setImmediatelyType(false);
        _numberTypeList.count = 5;
        setNumberTypeList([_numberTypeList]);
      } else {
        setScheduleType(true);
        setImmediatelyType(false);
        setNumberType(false);
        if (
          value.function_type === "CUSTOMER_BIRTHDAY_ALARM" ||
          value.function_type === "ACTIVE_CUSTOMER_ALARM" ||
          value.function_type === "INACTIVE_CUSTOMER_ALARM"
        ) {
          _scheduleTypeList.dateCount = 0;
        } else {
          _scheduleTypeList.dateCount = 7;
        }

        _scheduleTypeList.dateCountType = "DAY";

        if (value.function_type === "CUSTOMER_NON_ATTENDANCE") {
          _scheduleTypeList.dateCountTiming = "PLUS";
          setIsCustomerNonAttendanceModal(true);
        } else if (
          value.function_type === "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
          value.function_type === "LESSON_TICKET_EXPIRE_DATE_ALARM" ||
          value.function_type === "CUSTOMER_BIRTHDAY_ALARM" ||
          value.function_type === "ACTIVE_CUSTOMER_ALARM" ||
          value.function_type === "INACTIVE_CUSTOMER_ALARM"
        ) {
          _scheduleTypeList.dateCountTiming = "EQUAL";
        } else {
          _scheduleTypeList.dateCountTiming = "MINUS";
        }

        _scheduleTypeList.dateCountTimingType = "MORNING";
        _scheduleTypeList.sendHour = 10;
        _scheduleTypeList.sendMinute = 0;

        setScheduleTypeList([_scheduleTypeList]);
      }

      setIsSelectOption(false);
    }
    setAutoInputWordListData(value);
    setCurrentMessageType("SMS");
    setMessageBody("");
    setMessageTitle("");
    setSelectCoupon({});
    setIsAdInformation(false);
    setIsPreview(false);
  };

  /**자동 문자명 onChange 핸들러 */
  const handleNameChange = (e) => {
    setAutoMessageName(e.target.value);
  };

  const openAdInfoModal = () => {
    setIsInfoClicked(!isInfoClicked);
  };

  // message.config({
  //   // top: 100,
  //   duration: 10,
  //   // maxCount: 10,
  // });
  /**자동 알림 제목 handler 함수*/
  const handleMessageTitle = (e) => {
    if (isPreview) {
      message.warning({
        content: "미리 보기 시에는 메세지 입력이 불가능합니다.",
        style: {
          marginTop: "90vh",
        },
        duration: 1,
      });
      return;
    }
    if (e.target.value.includes(`'`) || e.target.value.includes(`"`)) {
      return;
    }
    setCursorPosition(e.target.selectionStart);
    setMessageTitle(e.target.value);
  };

  /**자동 알림 내용(body) handler 함수*/
  const handleMessageBody = (e) => {
    if (isPreview) {
      message.warning({
        content: "미리 보기 시에는 메세지 입력이 불가능합니다.",
        style: {
          marginTop: "90vh",
        },
        duration: 1,
      });
      return;
    }
    if (e.target.value.includes(`'`) || e.target.value.includes(`"`)) {
      return;
    }
    setCursorPosition(e.target.selectionStart);
    setMessageBody(e.target.value);
  };

  /**전송기준-> 즉시 checkbox 클릭시*/
  const handleImmediatelyTypeChange = (e) => {
    setImmediatelyType(e.target.checked);
  };

  /**전송기준-> 일정 기준 checkbox 클릭시*/
  const handleSchduleTypeChange = (e) => {
    if (
      !numberType &&
      !e.target.checked &&
      (selectedOption.function_type === "MEMBER_TICKET_EXPIRE_ALARM" ||
        selectedOption.function_type === "LESSON_TICKET_EXPIRE_ALARM" ||
        selectedOption.function_type === "ACTIVE_CUSTOMER_ALARM" ||
        selectedOption.function_type === "INACTIVE_CUSTOMER_ALARM")
    ) {
      return;
    }
    if (!e.target.checked) {
      setScheduleTypeList([]);
    } else if (e.target.checked) {
      let newList = [
        {
          dateCount: 7,
          dateCountType: "DAY",
          dateCountTiming:
            selectedOption &&
            selectedOption.function_type === "CUSTOMER_NON_ATTENDANCE"
              ? "PLUS"
              : "MINUS",
          dateCountTimingType: "AFTERNOON",
          sendHour: 10,
          sendMinute: 0,
        },
      ];
      if (
        selectedOption.function_type === "MEMBER_TICKET_EXPIRE_DATE_ALARM" ||
        selectedOption.function_type === "LESSON_TICKET_EXPIRE_DATE_ALARM" ||
        selectedOption.function_type === "CUSTOMER_BIRTHDAY_ALARM"
      ) {
        newList[0].dateCountTiming = "EQUAL";
      }
      setScheduleTypeList([...newList]);
    }
    setScheduleType(e.target.checked);
  };

  /**전송기준-> 횟수 기준 checkbox 클릭시*/
  const handleNumberTypeChange = (e) => {
    if (
      !scheduleType &&
      !e.target.checked &&
      (selectedOption.function_type === "MEMBER_TICKET_EXPIRE_ALARM" ||
        selectedOption.function_type === "LESSON_TICKET_EXPIRE_ALARM")
    ) {
      return;
    }
    if (e.target.checked) {
      const _numberTypeList = {
        count: 5,
      };

      setNumberTypeList([_numberTypeList]);
    } else if (!e.target.checked) {
      setNumberTypeList([]);
    }

    setNumberType(e.target.checked);
  };

  /**전송기준 -> 일정 기준 -> date Input 값 handler 함수 */
  const handleDateCountChange = (e, idx) => {
    if (isNaN(e.target.value)) {
      return;
    }
    if (e.target.value === "0" || e.target.value === "") {
      let _scheduleTypeList = scheduleTypeList;
      if (!selectedOption.function_type === "CUSTOMER_NON_ATTENDANCE") {
        _scheduleTypeList[idx].dateCountTiming = "EQUAL";
      }
      _scheduleTypeList[idx].dateCount = Number(e.target.value);
      setScheduleTypeList([..._scheduleTypeList]);
      return;
    }

    const _newScheduletypeList = scheduleTypeList;
    _newScheduletypeList[idx].dateCount = Number(e.target.value);
    setScheduleTypeList([..._newScheduletypeList]);
  };

  /**전송기준 -> 일정 기준 -> 일or주 값 handler 함수 */
  const handleDateCountTypeChange = (e, idx) => {
    const _newScheduletypeList = scheduleTypeList;
    if (e.key === "day") {
      _newScheduletypeList[idx].dateCountType = "DAY";
      setScheduleTypeList([..._newScheduletypeList]);
      // setDateCountType("DAY");
    } else {
      _newScheduletypeList[idx].dateCountType = "WEEK";
      setScheduleTypeList([..._newScheduletypeList]);
      // setDateCountType("WEEK");
    }
  };

  /**전송기준 -> 일정 기준 -> 알림 보내는 타이밍 값 handler 함수 */
  const handleDateCountTimingChange = (e, idx) => {
    // console.log(e);
    // console.log(idx);
    const _newScheduletypeList = scheduleTypeList;
    if (e.key === "minus") {
      // console.log("전");
      _newScheduletypeList[idx].dateCountTiming = "MINUS";
      // if (_newScheduletypeList.length > 1) {

      //   _newScheduletypeList[idx].dateCount =
      //     _newScheduletypeList[idx - 1].dateCount + 7;
      // } else {
      //   _newScheduletypeList[idx].dateCount = 7;
      // }
      setScheduleTypeList([..._newScheduletypeList]);
      // setDateCountTiming("MINUS");
    } else if (e.key === "plus") {
      // console.log("후");
      _newScheduletypeList[idx].dateCountTiming = "PLUS";
      // if (_newScheduletypeList.length > 1) {
      //   _newScheduletypeList[idx].dateCount =
      //     _newScheduletypeList[idx - 1].dateCount + 7;
      // } else {
      //   _newScheduletypeList[idx].dateCount = 7;
      // }
      setScheduleTypeList([..._newScheduletypeList]);
      // setDateCountTiming("PLUS");
    } else {
      // console.log("당일");
      _newScheduletypeList[idx].dateCountTiming = "EQUAL";
      setScheduleTypeList([..._newScheduletypeList]);
      // setDateCountTiming("EQUAL");
      // setDateCount(0);
    }
  };
  /**전송기준 -> 일정 기준 -> 시간 계산(오전,오후) handler 함수 */
  const hangleDateCountTimingTypeChange = (e, idx) => {
    const _newScheduletypeList = scheduleTypeList;
    if (e.key === "afternoon") {
      _newScheduletypeList[idx].dateCountTimingType = "AFTERNOON";
      setScheduleTypeList([..._newScheduletypeList]);
      // setDateCountTimingType("AFTERNOON");
    } else {
      _newScheduletypeList[idx].dateCountTimingType = "MORNING";
      setScheduleTypeList([..._newScheduletypeList]);
      // setDateCountTimingType("MORNING");
    }
  };

  /**전송기준 -> 일정 기준 -> 시간(hour) handler 함수 */
  const handleSendHourChange = (e, idx) => {
    const _newScheduletypeList = scheduleTypeList;
    if (
      isNaN(e.target.value) ||
      Number(e.target.value) > 12
      // Number(e.target.value) === 0
      // e.target.value > ""
    ) {
      return;
    }
    _newScheduletypeList[idx].sendHour = Number(e.target.value);
    // setSendHour(e.target.value);
    setScheduleTypeList([..._newScheduletypeList]);
  };

  const handleSendMinuteChange = (e, idx) => {
    const _newScheduletypeList = scheduleTypeList;
    if (isNaN(e.target.value) || Number(e.target.value) > 59) {
      return;
    }
    _newScheduletypeList[idx].sendMinute = Number(e.target.value);
    // setSendHour(e.target.value);
    setScheduleTypeList([..._newScheduletypeList]);
  };

  /**일정 기준에서 전송시간 추가 버튼 handler 함수 */
  const addScheduleTypeList = () => {
    const newScheduleType = {
      dateCount:
        Number(scheduleTypeList[scheduleTypeList.length - 1].dateCount) + 7,
      dateCountType:
        scheduleTypeList[scheduleTypeList.length - 1].dateCountType,
      dateCountTiming:
        scheduleTypeList[scheduleTypeList.length - 1].dateCountTiming,
      dateCountTimingType:
        scheduleTypeList[scheduleTypeList.length - 1].dateCountTimingType,
      sendHour: scheduleTypeList[scheduleTypeList.length - 1].sendHour,
      sendMinute: scheduleTypeList[scheduleTypeList.length - 1].sendMinute,
    };
    setScheduleTypeList((prev) => [...prev, newScheduleType]);
  };

  const openInfoModal = () => {
    setIsInfoClicked(!isInfoClicked);
  };
  /** 횟수 기준에서 전송시간 추가 버튼 handler 함수 */
  const addNumverTypeList = () => {
    const newNumberType = {
      count: Number(numberTypeList[numberTypeList.length - 1].count) + 5,
    };
    setNumberTypeList((prev) => [...prev, newNumberType]);
  };

  /**장기 미출석 선택시 뜨는 모달 close 함수 */
  const closeIsCustomerNonAttendanceModal = () => {
    setIsCustomerNonAttendanceModal(false);
  };

  /**전송기준에서 횟수기준일 때 count handler 함수*/
  const handleCountChange = (e, idx) => {
    if (isNaN(e.target.value)) {
      return;
    }
    const _newNumberTypeList = numberTypeList;
    _newNumberTypeList[idx].count = Number(e.target.value);
    setNumberTypeList([..._newNumberTypeList]);
  };

  /**전송 방법 handler 함수 */
  const handleAutoMessageType = (e) => {
    const _newAutoMessageType = {
      type: "",
      label: "",
      labelText: "",
    };
    switch (e.key) {
      case "sms":
        _newAutoMessageType.type = "SMS";
        _newAutoMessageType.label = "문자메시지";
        _newAutoMessageType.labelText = "문자메시지로 알림을 전송합니다.";
        setAutoMessageType({ ..._newAutoMessageType });
        break;
      case "push":
        _newAutoMessageType.type = "PUSH";
        _newAutoMessageType.label = "운톡 푸쉬 알림";
        _newAutoMessageType.labelText = "운톡 어플로 알림을 전송합니다.";
        setAutoMessageType({ ..._newAutoMessageType });
        break;
      case "double":
        _newAutoMessageType.type = "DOUBLE";
        _newAutoMessageType.label = "문자메시지 + 운톡 푸쉬 알림";
        _newAutoMessageType.labelText =
          "문자메시지와 운톡푸쉬알림을 동시에 전송합니다.";
        setAutoMessageType({ ..._newAutoMessageType });
        break;
      case "smart_push":
        _newAutoMessageType.type = "SMART_PUSH";
        _newAutoMessageType.label = "스마트 전송";
        _newAutoMessageType.labelText =
          "운톡가입이 되어있는 고객에게는 운톡푸쉬알림으로, 그 외 고객에게는 문자메시지로 전송합니다.";
        setAutoMessageType({ ..._newAutoMessageType });
        break;

      default:
        break;
    }
  };
  /**쿠폰 선택 handler 함수 */
  const handleSelectCoupon = (e) => {
    const selectedCoupon = couponList.filter((data) => data.jgcp_key == e.key);
    const newSelectedCoupon = {
      label: selectedCoupon[0].jgcp_name,
      coupon_key: e.key,
    };

    setSelectCoupon({ ...newSelectedCoupon });

    // if (e.key == -1) {
    //   let _newMessageTitle = messageTitle.replace("(광고)", "");
    //   setMessageTitle(_newMessageTitle);
    //   setIsAdInformation(false);
    //   return;
    // } else if (e.key > 0) {
    // setIsAdInformation(true);
    // }
    // setMessageTitle("(광고)");
  };

  /**광고성 정보 동의 handler 함수 */
  const handleAdInfoChange = (e) => {
    // if (selectCoupon.coupon_key > 0) {
    //   return;
    // }
    setIsAdInformation(e.target.checked);
    if (e.target.checked) {
      setMessageTitle(`(광고)${messageTitle ? messageTitle : ""}`);
    } else {
      const bodyByte = getByteLength(messageBody);
      setMessageTitle(
        `${messageTitle ? messageTitle.slice(4, messageTitle.length) : ""}`
      );
      if (messageTitle.length < 1) {
        setByteValue(bodyByte);
      }
    }
  };

  /**자동 입력 문구 버튼 handler 함수 */
  const handleClickAutoMessageWord = (e, data) => {
    if (isPreview) {
      message.warning({
        content: "미리 보기 시에는 메세지 입력이 불가능합니다.",
        style: {
          marginTop: "90vh",
        },
        duration: 1,
      });
      return;
    }
    e.preventDefault();
    if (cursorPosition !== null) {
      let newMessageText = "";
      if (focusedInput === "title") {
        newMessageText = `${messageTitle.slice(0, cursorPosition)}${
          data.label
        }${messageTitle.slice(cursorPosition)}`;
        setMessageTitle(newMessageText);
        formRefs.current.setFieldsValue({
          body: newMessageText,
        });
      } else {
        newMessageText = `${messageBody.slice(0, cursorPosition)}${
          data.label
        }${messageBody.slice(cursorPosition)}`;
        setMessageBody(newMessageText);
        formRefs.current.setFieldsValue({
          body: newMessageText,
        });
      }
      // setTitle(newValue);

      setCursorPosition(cursorPosition + data.label.length);
      // #상품명# 길이만큼 커서 위치 조정
    }
  };
  /**byte 계산 함수 */
  const getByteLength = (str) => {
    let byte = 0;
    for (let i = 0; i < str.length; i++) {
      const code = str.charCodeAt(i);
      if (code > 127) {
        byte += 2;
      } else {
        byte += 1;
      }
    }
    return byte;
  };

  const onFinish = () => {
    if (selectedOption.function_type.length <= 0) {
      message.warning({
        content: "미리 보기 시에는 메세지 입력이 불가능합니다.",
        style: {
          marginTop: "90vh",
        },
        duration: 1,
      });
      return;
    }
    if (autoMessageName.length <= 0) {
      message.warning({
        content: "자동알림명을 입력해 주세요.",
        style: {
          marginTop: "90vh",
        },
        duration: 1,
      });
      return;
    }
    if (selectedOption.send_time_type === "IMMEDIATELY") {
      if (!immediatelyType) {
        message.warning({
          content: "전송 기준을 선택해 주세요.",
          style: {
            marginTop: "90vh",
          },
          duration: 1,
        });
        return;
      }
    }
    if (messageTitle.length <= 0) {
      message.warning({
        content: "메시지 제목을 입력해 주세요.",
        style: {
          marginTop: "90vh",
        },
        duration: 1,
      });
      return;
    }
    if (messageBody.length <= 0) {
      message.warning({
        content: "메시지 내용을 입력해 주세요.",
        style: {
          marginTop: "90vh",
        },
        duration: 1,
      });
      return;
    }

    let _auto_message_type_list = [];
    let _entry_list = [];

    //전송기준이 즉시일 경우
    if (selectedOption.send_time_type === "IMMEDIATELY") {
      _entry_list.push({
        entry_key: null,
        send_time_type: "IMMEDIATELY",
        unit_count: 0,
        entry_type: "EQUAL",
        entry_unit: "COUNT",
        hour: 0,
        minute: 0,
      });
      //전송기준이 일정 기준일 경우
    } else if (selectedOption.send_time_type === "RELATIVELY_TIME") {
      if (scheduleType && numberType) {
        let timeTypeList =
          scheduleTypeList.length > 0 &&
          scheduleTypeList.map((data) => {
            let _hour = 0;
            if (data.dateCountTimingType === "MORNING") {
              if (Number(data.sendHour) === 12) {
                _hour = 0;
              } else {
                _hour = data.sendHour;
              }
            } else if (data.dateCountTimingType === "AFTERNOON") {
              if (Number(data.sendHour) === 12) {
                _hour = 12;
              } else {
                _hour = data.sendHour + 12;
              }
            }
            if (data.dateCountTiming === "EQUAL") {
              data.dateCount = 0;
            }

            return {
              entry_key: null,
              send_time_type: "RELATIVELY_TIME",
              unit_count: data.dateCount,
              entry_type: data.dateCountTiming,
              entry_unit: data.dateCountType,
              hour: _hour,
              minute: data.sendMinute,
            };
          });
        let countTypeList =
          numberTypeList.length > 0 &&
          numberTypeList.map((data) => {
            return {
              entry_key: null,
              send_time_type: "RELATIVELY_COUNT",
              unit_count: data.count,
              entry_type: "MINUS",
              entry_unit: "COUNT",
              hour: 0,
              minute: 0,
            };
          });

        _entry_list = [...timeTypeList, ...countTypeList];
      }
      if (scheduleType && !numberType) {
        let timeTypeList =
          scheduleTypeList.length > 0 &&
          scheduleTypeList.map((data) => {
            let _hour = 0;
            if (data.dateCountTimingType === "MORNING") {
              if (Number(data.sendHour) === 12) {
                _hour = 0;
              } else {
                _hour = data.sendHour;
              }
            } else if (data.dateCountTimingType === "AFTERNOON") {
              if (Number(data.sendHour) === 12) {
                _hour = 12;
              } else {
                _hour = data.sendHour + 12;
              }
            }
            if (data.dateCountTiming === "EQUAL") {
              data.dateCount = 0;
            }
            return {
              entry_key: null,
              send_time_type: "RELATIVELY_TIME",
              unit_count: data.dateCount,
              entry_type: data.dateCountTiming,
              entry_unit: data.dateCountType,
              hour: _hour,
              minute: data.sendMinute,
            };
          });
        _entry_list = [...timeTypeList];
      }
      if (numberType && !scheduleType) {
        let countTypeList =
          numberTypeList.length > 0 &&
          numberTypeList.map((data) => {
            return {
              entry_key: null,
              send_time_type: "RELATIVELY_COUNT",
              unit_count: data.count,
              entry_type: "MINUS",
              entry_unit: "COUNT",
              hour: 0,
              minute: 0,
            };
          });

        _entry_list = [...countTypeList];
      }
    }
    if (autoMessageType.type === "SMS") {
      //자동 알림 type list 배열 data 가공
      _auto_message_type_list.push("SMS");
    } else if (autoMessageType.type === "PUSH") {
      _auto_message_type_list.push("PUSH");
    } else if (autoMessageType.type === "DOUBLE") {
      _auto_message_type_list.push("SMS");
      _auto_message_type_list.push("PUSH");
    } else if (autoMessageType.type === "SMART_PUSH") {
      _auto_message_type_list.push("SMART_PUSH");
    }

    let _newtitle = messageTitle.replace("(광고)", "");
    const createAutoMessageData = {
      name: autoMessageName ? autoMessageName : "",
      title: _newtitle ? _newtitle : "",
      body: messageBody ? messageBody : "",
      function_type:
        selectedOption.function_type && selectedOption.function_type,
      auto_message_type_list: _auto_message_type_list,
      entry_list: _entry_list,
      status: "ENABLED",
      calling_number: selectedNumber ?? selectedNumber,
      coupon_parent_key: selectCoupon.coupon_key
        ? selectCoupon.coupon_key
        : null,
      has_advertisement: isAdInformation,
    };
    if (isModify && selectedData) {
      createAutoMessageData.status = isActive ? "ENABLED" : "DISABLED";
      dispatch(
        modifyAutoMessageSettigAction({
          modifyData: createAutoMessageData,
          auto_message_key: selectedData.auto_message_key,
          jgroup_key: jgroupKey,
        })
      );
    } else {
      // console.log("!!!!!!", createAutoMessageData);
      dispatch(
        createAutoMessageAction({
          createAutoMessageData: createAutoMessageData,
          jgroup_key: jgroupKey,
        })
      );
    }
    setFinished(true);
  };

  const titleInputClick = (e) => {
    setCursorPosition(e.target.selectionStart);
  };

  const titleInputOnkeyUp = (e) => {
    setCursorPosition(e.target.selectionStart);
  };

  const bodyInputClick = (e) => {
    setCursorPosition(e.target.selectionStart);
  };

  const bodyInputOnkeyUp = (e) => {
    setCursorPosition(e.target.selectionStart);
  };

  const handleIsActiveToggle = () => {
    setIsActive((isActive) => !isActive);
  };
  // console.log(isActive);
  const MenuItem = Menu.Item;

  const menuStyle = {
    backgroundColor: "#4a4a4a",
  };

  const menuItemStyle = {
    background: "none",
    color: "#ffffff",
  };

  const automessageTypeList = (
    <Menu style={menuStyle} onClick={handleAutoMessageType}>
      {selectedNumber ? (
        <MenuItem key="sms" style={menuItemStyle}>
          문자메시지
        </MenuItem>
      ) : null}
      <MenuItem key="push" style={menuItemStyle}>
        운톡 푸쉬 알림
      </MenuItem>
      {selectedNumber ? (
        <MenuItem key="double" style={menuItemStyle}>
          문자메시지 + 운톡 푸쉬 알림
        </MenuItem>
      ) : null}
      <MenuItem key="smart_push" style={menuItemStyle}>
        스마트 전송
      </MenuItem>
    </Menu>
  );

  const couponItemStyle = {
    background: "none",
    // background: "red",
    color: "#ffffff",
    display: "flex",
    // flexDirection: "row",
    // height: "100px",
  };

  const couponMenuStyle2 = {
    backgroundColor: "#4a4a4a",
    height: "300px",
    overflowY: "scroll",
  };

  const coupondataList = (
    <Menu style={couponMenuStyle2} onClick={handleSelectCoupon}>
      {/* <MenuItem key="sms" style={menuItemStyle}>
        문자
      </MenuItem>
      <MenuItem key="push" style={menuItemStyle}>
        운톡 푸쉬 알림
      </MenuItem>
      <MenuItem key="double" style={menuItemStyle}>
        문자 + 운톡 푸쉬 알림
      </MenuItem>
      <MenuItem key="smart_push" style={menuItemStyle}>
        문자 + 운톡 푸쉬 알림
      </MenuItem> */}
      {couponList && couponList.length > 0 ? (
        couponList.map((data) => {
          return (
            <MenuItem key={data.jgcp_key} style={couponItemStyle}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <Text>{data.jgcp_name}</Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  {data.period_type && (
                    <Text color="#A4A4A4">
                      {data.period_type === "DAYS"
                        ? `발행일로부터 ${data.jgcp_preiod_day}일 이내`
                        : `${moment(data.jgcp_period_start).format(
                            "YYYY.MM.DD"
                          )} ~ ${moment(data.jgcp_period_end).format(
                            "YYYY.MM.DD"
                          )}`}
                    </Text>
                  )}
                  {data.jgcp_limited && (
                    <Text>
                      {data.jgcp_limited
                        ? `${
                            data.jgcp_limit_count - data.issued_coupons_size
                          }/${data.jgcp_limit_count}`
                        : "무제한"}
                    </Text>
                  )}
                </div>
              </div>
            </MenuItem>
          );
        })
      ) : (
        <MenuItem>사용 가능한 쿠폰이 없습니다.</MenuItem>
      )}
    </Menu>
  );

  const formRefs = useRef();
  const sendHourInputRef = useRef();

  return (
    <ModalScreen className="message-setting-modal">
      <PositionController>
        <Form ref={formRefs} onFinish={onFinish}>
          <FormContainer ref={formRef}>
            <ModalHeader>
              <LeftHeader>
                <Title>자동알림 설정 추가</Title>
              </LeftHeader>
              <RightHeader style={{ flexDirection: "row" }}>
                <Spacer size={5} />
                {isModify && (
                  <IsUse onClick={handleIsActiveToggle} type="button">
                    <Text style={{ fontWeight: "500" }}>사용 여부</Text>{" "}
                    <Form.Item name="active">
                      <Switch checked={isActive} name="active" />
                    </Form.Item>
                  </IsUse>
                )}
                <Spacer size={10} />
                <CloseButton
                  type="button"
                  onClick={() => {
                    if (isModify) {
                      handleSetCancel();
                    } else {
                      closeSetAddModal();
                    }
                  }}
                >
                  <CloseIcon
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icon/white/x.png"
                    }
                  ></CloseIcon>
                </CloseButton>
              </RightHeader>
            </ModalHeader>
            <FormWrapper isSelectOption={isSelectOption}>
              <Spacer size="12" />
              <div
                type="button"
                onClick={handleIsSelectOption}
                style={{
                  padding: "12px",
                  borderRadius: "24px",
                  border: "1px solid #FA943D",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div>
                  <Text color="#A4A4A4">
                    {selectedOption.label &&
                    selectedOption.related === "customer"
                      ? "고객관련 > "
                      : selectedOption.label &&
                        selectedOption.related === "product"
                      ? "상품관련 > "
                      : "옵션을 선택해주세요. "}
                  </Text>
                  <Text>
                    {selectedOption.label ? selectedOption.label : ""}
                  </Text>
                </div>
                <Icon
                  width="12px"
                  height="6px"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icon/select/toggle_down.png"
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  position: "absolute",
                  height: isSelectOption ? "300px" : "0px",
                  width: "93.5%",
                  transition: "0.2s",
                }}
              >
                <SelectProductWrapper enabled={isSelectOption}>
                  <div
                    style={{
                      flexWrap: "wrap",
                      background: "#4A4A4A",
                      padding: "12px",
                      height: "267px",
                      borderRadius: "24px",
                    }}
                  >
                    <Content>
                      <Tabs
                        className="modal-Tabs"
                        defaultActiveKey="1"
                        style={{ padding: "12px" }}
                      >
                        <TabPane tab="고객 관련" key="1">
                          <div style={{ marginTop: "16px" }}>
                            <Row gutter={[20, 20]}>
                              {customerRealtiveOptionList.map((data, index) => (
                                <Col span={6} style={{ padding: "0px" }}>
                                  <CustomStyleBtn
                                    type="button"
                                    onClick={() => handleSelectOptionBtn(data)}
                                    disabled={data.disabled ? true : false}
                                    padding="5px"
                                    key={data.id}
                                    background="#4a4a4a"
                                  >
                                    <Text color={data.disabled && "#A4A4A4"}>
                                      {data.label ? data.label : ""}
                                    </Text>
                                  </CustomStyleBtn>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </TabPane>
                        <TabPane tab="상품 관련" key="2">
                          <div style={{ marginTop: "16px" }}>
                            <Row gutter={[20, 24]}>
                              {productRelativeOptionList.map((data, index) => (
                                <Col span={6} style={{ padding: "0px" }}>
                                  <CustomStyleBtn
                                    type="button"
                                    onClick={() => handleSelectOptionBtn(data)}
                                    disabled={data.disabled ? true : false}
                                    padding="5px"
                                    key={data.id}
                                    background="#4a4a4a"
                                  >
                                    <Text color={data.disabled && "#A4A4A4"}>
                                      {data.label}
                                    </Text>
                                  </CustomStyleBtn>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </TabPane>
                      </Tabs>
                    </Content>
                  </div>
                </SelectProductWrapper>
              </div>
              <Spacer size="12" />
              <div
                className={
                  selectedOption && selectedOption.label ? "" : "disabled"
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <LeftContent>
                    <Text d color="#FA943D" fontWeight="700">
                      01
                    </Text>
                    <Text style={{ paddingLeft: "12px" }}>자동알림명</Text>
                  </LeftContent>
                  <Form.Item
                  // name="automessage-name"
                  // // initialValue={autoMessageName}
                  // rules={[{ validator: validateAutoMessageName }]}
                  >
                    <RightContent>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Input
                          // name="automessage-name"
                          placeholder="자동알림 템플릿 명을 입력해주세요"
                          style={{ padding: "12px", width: "100%" }}
                          autoComplete="off"
                          // defaultValue={autoMessageName}
                          value={autoMessageName}
                          onChange={handleNameChange}
                        />
                        {selectedOption &&
                          (selectedOption.function_type ===
                            "ACTIVE_CUSTOMER_ALARM" ||
                            selectedOption.function_type ===
                              "INACTIVE_CUSTOMER_ALARM") && (
                            <AutomaticMessageNameLabel
                              function_type={selectedOption.function_type}
                            />
                          )}
                      </div>
                    </RightContent>
                  </Form.Item>
                </div>
                <Spacer size="12" />
                <Content
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    overflow: "visible",
                  }}
                >
                  <LeftContent>
                    <Text d color="#FA943D" fontWeight="700">
                      02
                    </Text>
                    <Text style={{ paddingLeft: "12px" }}>전송 기준</Text>
                  </LeftContent>
                  <RightContent
                    style={{
                      flexDirection: "column",
                      // justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Form.Item>
                        <Checkbox
                          checked={immediatelyType}
                          onChange={handleImmediatelyTypeChange}
                          disabled={
                            selectedOption.send_time_type === "IMMEDIATELY"
                              ? false
                              : true
                          }
                        >
                          즉시
                        </Checkbox>
                      </Form.Item>
                      <Form.Item>
                        <Checkbox
                          checked={scheduleType}
                          onChange={handleSchduleTypeChange}
                          disabled={
                            selectedOption.send_time_type === "RELATIVELY_TIME"
                              ? false
                              : true
                          }
                        >
                          일정 기준
                        </Checkbox>
                      </Form.Item>
                      <Form.Item>
                        <Checkbox
                          checked={numberType}
                          onChange={handleNumberTypeChange}
                          disabled={
                            selectedOption.function_type ===
                              "MEMBER_TICKET_EXPIRE_ALARM" ||
                            selectedOption.function_type ===
                              "LESSON_TICKET_EXPIRE_ALARM"
                              ? false
                              : true
                          }
                        >
                          횟수 기준
                        </Checkbox>
                      </Form.Item>
                    </div>
                    {/* </Content> */}
                    {!scheduleType && numberType ? null : (
                      <Divider
                        style={{ marginTop: "12px", marginBottom: "12px" }}
                      />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {immediatelyType ? (
                          <Text fontWeight="700">
                            {`'${selectedOption.label}' 즉시 전송만 가능`}
                          </Text>
                        ) : scheduleType ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            {scheduleTypeList &&
                              scheduleTypeList.length > 0 &&
                              scheduleTypeList.map((data, idx) => {
                                return (
                                  <ScheduleTypeData
                                    scheduleTypeList={scheduleTypeList}
                                    setScheduleTypeList={setScheduleTypeList}
                                    key={idx}
                                    idx={idx}
                                    dateCount={data.dateCount}
                                    dateCountTiming={data.dateCountTiming}
                                    dateCountType={data.dateCountType}
                                    dateCountTimingType={
                                      data.dateCountTimingType
                                    }
                                    sendHour={data.sendHour}
                                    sendMinute={data.sendMinute}
                                    selectedOption={selectedOption}
                                    handleDateCountChange={
                                      handleDateCountChange
                                    }
                                    // dateCountTypeList={dateCountTypeList}
                                    // dateCountTimingList={dateCountTimingList}
                                    // dateCountTimingTypeList={
                                    //   dateCountTimingTypeList
                                    // }
                                    handleSendHourChange={handleSendHourChange}
                                    handleDateCountTypeChange={
                                      handleDateCountTypeChange
                                    }
                                    handleDateCountTimingChange={
                                      handleDateCountTimingChange
                                    }
                                    hangleDateCountTimingTypeChange={
                                      hangleDateCountTimingTypeChange
                                    }
                                    sendHourInputRef={sendHourInputRef}
                                    handleSendMinuteChange={
                                      handleSendMinuteChange
                                    }
                                  />
                                );
                              })}
                            <div
                              style={{
                                marginTop: "12px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text>
                                *현재 시간보다 이전으로 설정할 경우 익일 알림이
                                발송됩니다.
                              </Text>
                              <Text>
                                *자동알림은{" "}
                                <Text
                                  style={{ textDecoration: "underLine" }}
                                  color="#FA6400"
                                >
                                  최초 1회 발송 후 자동
                                </Text>
                                으로 꺼집니다.
                              </Text>
                            </div>

                            <div style={{ marginTop: "12px" }}>
                              <CustomStyleBtn
                                type="button"
                                onClick={addScheduleTypeList}
                                style={{ padding: "12px 85px 12px 85px" }}
                                background="#4A4A4A"
                                borderRadius="22px"
                              >
                                <Icon
                                  width="20px"
                                  height="20px"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icon/white/plus.png"
                                  }
                                  style={{ marginRight: "6px" }}
                                />

                                <Text fontWeight="700">전송시간 추가</Text>
                              </CustomStyleBtn>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {numberType ? (
                        <Divider
                          style={{ marginTop: "12px", marginBottom: "12px" }}
                        />
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        {numberType ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            {numberTypeList &&
                              numberTypeList.length > 0 &&
                              numberTypeList.map((data, idx) => {
                                return (
                                  <NumberTypeData
                                    key={idx}
                                    idx={idx}
                                    selectedOption={selectedOption}
                                    count={data.count}
                                    handleCountChange={handleCountChange}
                                    numberTypeList={numberTypeList}
                                    setNumberTypeList={setNumberTypeList}
                                  />
                                );
                              })}
                            <div style={{ marginTop: "12px" }}>
                              <CustomStyleBtn
                                type="button"
                                onClick={addNumverTypeList}
                                style={{ padding: "12px 85px 12px 85px" }}
                                background="#4A4A4A"
                                borderRadius="22px"
                              >
                                <Icon
                                  width="20px"
                                  height="20px"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icon/white/plus.png"
                                  }
                                  style={{ marginRight: "6px" }}
                                />

                                <Text fontWeight="700">전송시간 추가</Text>
                              </CustomStyleBtn>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </RightContent>
                </Content>
                <Spacer size="12" />
                <Content
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    overflow: "visible",
                  }}
                >
                  <LeftContent>
                    <Text d color="#FA943D" fontWeight="700">
                      03
                    </Text>
                    <Text style={{ paddingLeft: "12px" }}>전송 방법</Text>
                  </LeftContent>
                  <RightContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Form.Item style={{ width: "100%" }}>
                      <Dropdown
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        popupStyle={{ zIndex: 10500 }}
                        trigger={["click"]}
                        overlay={automessageTypeList}
                        // position="relative"
                        overlayStyle={{ position: "absolute" }}
                        style={{ height: "100%" }}
                      >
                        <div
                          style={{
                            background: "#4a4a4a",
                            padding: "12px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            // marginLeft: "10px",
                            // cursor: "pointer",
                            // position: "relative",
                            height: "100%",
                            borderRadius: "5px",
                          }}
                        >
                          <Text>
                            {autoMessageType && autoMessageType.label
                              ? autoMessageType.label
                              : "전송 방법을 선택해주세요."}
                          </Text>

                          <Icon
                            width="12px"
                            height="6px"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icon/select/toggle_down.png"
                            }
                          />
                        </div>
                      </Dropdown>
                    </Form.Item>
                    <Text style={{ marginTop: "6px" }} color="#a4a4a4">
                      {`* ${
                        autoMessageType && autoMessageType.labelText
                          ? autoMessageType.labelText
                          : ""
                      }`}
                    </Text>
                    <Text color="#EB5252">
                      {autoMessageType.type !== "PUSH"
                        ? `* 발신번호 미등록시 문자메시지가 전송되지 않습니다.`
                        : ""}
                    </Text>
                    <Text color="#EB5252">
                      {autoMessageType.type !== "PUSH"
                        ? `* 문자포인트가 소진될 경우 문자메시지가 전송되지 않습니다.`
                        : ""}
                    </Text>
                  </RightContent>
                </Content>
                <Spacer size="12" />
                <Content
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <LeftContent>
                    <Text d color="#FA943D" fontWeight="700">
                      04
                    </Text>
                    <Text style={{ paddingLeft: "12px" }}>파일 첨부</Text>
                  </LeftContent>
                  <RightContent
                    style={{
                      flexDirection: "column",
                      // justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <CustomStyleBtn
                      type="button"
                      style={{ padding: "12px 70px 12px 70px", opacity: "50%" }}
                      background="#4A4A4A"
                      borderRadius="22px"
                      disabled={true}
                    >
                      <Text fontWeight="700">이미지 선택하기 (준비중)</Text>
                    </CustomStyleBtn>
                    <Spacer size="3" />
                    <Text color="#A4A4A4">
                      {`* 1440*1440px 이하 파일만 첨부 가능
                    `}
                    </Text>
                    <Text color="#A4A4A4">
                      {`
                    * 각 300KB (최대2장), jpg/jpeg 만 첨부 가능`}
                    </Text>
                  </RightContent>
                </Content>
                <Spacer size="12" />
                <Content
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    overflow: "visible",
                    // minHeight: "100px",
                  }}
                >
                  <LeftContent>
                    <Text color="#FA943D" fontWeight="700">
                      05
                    </Text>
                    <Text style={{ paddingLeft: "12px" }}>쿠폰 첨부</Text>
                  </LeftContent>
                  <RightContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Form.Item style={{ width: "100%" }}>
                      <Dropdown
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        popupStyle={{ zIndex: 10500 }}
                        trigger={["click"]}
                        overlay={coupondataList}
                        // position="relative"
                        overlayStyle={{ position: "absolute" }}
                        style={{ height: "100%" }}
                        disabled={
                          selectedOption.function_type ===
                          "CUSTOMER_COUPON_EXPIRE_ALARM"
                            ? true
                            : false
                        }
                      >
                        <div
                          style={{
                            background: "#4a4a4a",
                            padding: "12px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            // marginLeft: "10px",
                            // cursor: "pointer",
                            // position: "relative",
                            // height: "100%",
                            borderRadius: "5px",
                            opacity:
                              selectedOption.function_type ===
                              "CUSTOMER_COUPON_EXPIRE_ALARM"
                                ? "50%"
                                : null,
                          }}
                        >
                          <Text>
                            {selectCoupon && selectCoupon.label
                              ? selectCoupon.label
                              : "쿠폰 선택"}
                          </Text>
                          <Icon
                            width="12px"
                            height="6px"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icon/select/toggle_down.png"
                            }
                          />
                        </div>
                      </Dropdown>
                    </Form.Item>
                    <Text style={{ marginTop: "6px" }} color="#a4a4a4">
                      * 쿠폰은 최대 1개까지만 첨부 가능합니다.
                    </Text>
                    <Text color="#a4a4a4">
                      * 무제한 수량 쿠폰만 선택 가능합니다.
                    </Text>
                    <Text color="#FA943D">
                      * 쿠폰 첨부 시 "쿠폰이 발급되었습니다" 문구와 함께 쿠폰명, 링크가 포함되어 byte 차이가 발생할 수 있습니다.
                    </Text>
                  </RightContent>
                </Content>
                <Spacer size="12" />
                <Content
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    overflow: "visible",
                    // height: "100%",
                  }}
                >
                  <LeftContent>
                    <Text d color="#FA943D" fontWeight="700">
                      06
                    </Text>
                    <Text style={{ paddingLeft: "12px" }}>메세지 입력</Text>
                  </LeftContent>
                  <RightContent
                    style={{
                      flexDirection: "row",
                      // justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Content
                      style={{
                        display: "flex",
                        flex: 6,
                        flexDirection: "column",
                        // justifyContent: "flex-start",
                        alignItems: "flex-start",
                        overflow: "visible",
                        // height: "100%",
                        height: "380px",
                        maxHeight: "380px",
                      }}
                    >
                      <Content
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          // alignItems: "center",
                          overflow: "visible",
                        }}
                      >
                        <Content
                          style={{
                            flex: 3,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            overflow: "visible",
                          }}
                          className="ad_info_popover"
                        >
                          <Form.Item>
                            <Checkbox
                              checked={isAdInformation}
                              onChange={handleAdInfoChange}
                            />
                          </Form.Item>
                          <Spacer size="3" />
                          <Text>
                            <Text
                              color="#FA943D"
                              style={{
                                textDecorationLine: "underline",
                                cursor: "pointer",
                              }}
                              onClick={openInfoModal}
                            >
                              광고성 정보
                            </Text>
                            가 포함되어 있습니다.
                          </Text>

                          <Spacer size="3" />
                          <Popover
                            content={<AdInfoPopoverContent />}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            popupStyle={{
                              zIndex: 10002,
                            }}
                          >
                            <Icon
                              width="16px"
                              height="16px"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icon/select/Shape.png"
                              }
                            />
                          </Popover>
                        </Content>
                        <Content
                          style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            overflow: "visible",
                          }}
                        >
                          <Text>미리 보기</Text>
                          <Spacer size="3" />
                          <Switch
                            checked={isPreview}
                            onChange={() => {
                              if (isPreview) {
                                setIsPreview(false);
                                return;
                              }
                              const _previewTitle =
                                handleOnchangePreview(messageTitle);
                              const _previewBody =
                                handleOnchangePreview(messageBody);
                              setPreviewTitle(_previewTitle);
                              setPreviewText(_previewBody);
                              setIsPreview(true);
                            }}
                          />
                        </Content>
                      </Content>
                      <div
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#4A4A4A",
                          width: "100%",
                          // marginTop: "6px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#383838",
                            margin: "10px",
                            borderRadius: "20px",
                            padding: "12px",
                            display: "flex",
                            flexDirection: "column",
                            // height: "327px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                width="8px"
                                height="15px"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icon/arrow_left.png"
                                }
                              />
                              <Text style={{ marginLeft: "6px" }} size="12">
                                {selectedNumber
                                  ? `${selectedNumber.replace(
                                      /^(\d{3})(\d{4})(\d{4})$/,
                                      "$1-$2-$3"
                                    )}`
                                  : ""}
                              </Text>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                // pagging: "12px",
                                // backgroundColor: "#FA943D",
                                // color: "#ffffff",
                                // fontSize: "12px",
                                // alignSelf: "center",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "13px",
                                border:
                                  currentMessageType &&
                                  currentMessageType === "SMS"
                                    ? "1px solid #FA943D"
                                    : "1px solid #EB5252",

                                width: "70px",
                                height: "30px",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  borderRadius: "13px",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor:
                                    currentMessageType &&
                                    currentMessageType === "SMS"
                                      ? "#FA943D"
                                      : "#EB5252",
                                  opacity: "20%",
                                }}
                              ></span>
                              <Text
                                style={{ position: "relative", zIndex: 1 }}
                                size="12"
                                fontWeight="700"
                              >
                                {currentMessageType &&
                                currentMessageType === "SMS"
                                  ? "sms(15원)"
                                  : "LMS(35원)"}
                              </Text>
                            </div>
                          </div>
                          <Form.Item
                          // name="title"
                          // rules={[
                          //   {
                          //     validator: validateTitle,
                          //   },
                          // ]}
                          // style={{ paddingBottom: "10px" }}
                          >
                            <Input
                              // name="title"
                              // disabled={isPreview ? true : false}
                              onFocus={() => handleFocusInput("title")}
                              // onBlur={() => handleFocusInput(null)}
                              onClick={titleInputClick}
                              onKeyUp={titleInputOnkeyUp}
                              value={isPreview ? previewTitle : messageTitle}
                              onChange={handleMessageTitle}
                              style={{
                                background: "#E9E9E9",
                                width: "100%",
                                marginTop: "8px",
                                color: "#7C7C7C",
                                fontWeight: "700",
                                // paddingBottom: "8px",
                              }}
                              placeholder={
                                isAdInformation
                                  ? "(광고) 제목 입력"
                                  : "제목 입력"
                              }
                            />
                          </Form.Item>
                          <Form.Item
                          // name="body"
                          // style={{ paddingTop: "3px", paddingBottom: "10px" }}
                          // rules={[
                          //   {
                          //     validator: validateBody,
                          //   },
                          // ]}
                          >
                            <Input.TextArea
                              // autoSize="false"
                              // name="body"
                              // disabled={isPreview ? true : false}
                              value={isPreview ? previewText : messageBody}
                              onFocus={() => handleFocusInput("body")}
                              // onBlur={() => handleFocusInput(null)}
                              onChange={handleMessageBody}
                              onClick={bodyInputClick}
                              onKeyUp={bodyInputOnkeyUp}
                              style={{
                                background: "#E9E9E9",
                                width: "100%",
                                marginTop: "8px",
                                color: "#7C7C7C",
                                fontWeight: "700",
                                minHeight: "210px",
                                // height: "210px",
                              }}
                              placeholder={`내용 입력\n90byte 초과시 LMS로 자동 전환됩니다.(제목 포함)\n특수문자 및 이모지 입력은 전송 시 입력되지 않습니다.(제목 포함)
                          `}
                            />
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {isAdInformation ? (
                                <div>
                                  <Text
                                    style={{
                                      // position: "absolute",
                                      display: "flex",

                                      // bottom: 10,
                                      // left: 2,
                                    }}
                                    color="###"
                                  >
                                    {`${jgroupData?.jgroup_name}`}
                                  </Text>
                                  <Text
                                    style={{
                                      // position: "absolute",
                                      display: "flex",

                                      // bottom: 10,
                                      // left: 2,
                                    }}
                                    color="###"
                                  >
                                    무료수신거부 080-156-9801
                                  </Text>
                                </div>
                              ) : (
                                <Text></Text>
                              )}
                              <span
                                style={{
                                  // position: "absolute",
                                  display: "flex",
                                  // bottom: 10,
                                  // right: 2,
                                  // left: 1,
                                }}
                              >
                                <Text
                                  style={
                                    {
                                      // position: "absolute",
                                      // display: "flex",
                                      // bottom: 10,
                                      // right: 0,
                                      // left: 0,
                                    }
                                  }
                                  color="###"
                                >
                                  {byteValue}/
                                </Text>
                                <Text color="#FA943D"> 90 Byte</Text>
                              </span>
                            </span>
                          </Form.Item>
                        </div>
                      </div>
                    </Content>
                    <Content
                      style={{
                        display: "flex",
                        flex: 3,
                        paddingLeft: "16px",
                        overflow: "visible",
                        height: "380px",
                        // maxHeight: "500px",
                        // overflowY: "scroll",
                        // margin: "12px",
                        padding: "12px",
                        backgroundColor: "#4A4A4A",
                        borderRadius: "20px",
                        marginLeft: "24px",
                      }}
                      className="auto_input_popover"
                    >
                      <div
                        style={{
                          borderRadius: "20px",
                          display: "flex",
                          flexDirection: "column",
                          // padding: "12px",
                          flex: 1,
                          // minHeight: "375px",

                          // scr
                        }}
                      >
                        {/* <AutoInputWord> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text>자동 입력 문구</Text>
                          <Popover
                            content={<AutoInputPopoverContent />}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            popupStyle={{
                              zIndex: 10002,
                            }}
                          >
                            <Icon
                              style={{ marginLeft: "3px" }}
                              width="16px"
                              height="16px"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icon/select/Shape.png"
                              }
                            />
                          </Popover>
                        </div>
                        <Text size="12" color="#A4A4A4">
                          * 클릭시 내용이 자동 입력됩니다.
                        </Text>
                        <div
                          ref={autoInputWordRef}
                          style={{
                            overflowY: "scroll",
                          }}
                        >
                          {autoInputWordList &&
                            autoInputWordList.length > 0 &&
                            autoInputWordList.map((data) => {
                              return (
                                <div
                                  type="button"
                                  onClick={(e) =>
                                    handleClickAutoMessageWord(e, data)
                                  }
                                  key={data.dataType}
                                  style={{
                                    position: "relative",
                                    padding: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "5px",
                                    marginTop: "8px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    // type="button"
                                    // onClick={handleClickAutoMessageWord}
                                    style={{
                                      position: "absolute",
                                      borderRadius: "13px",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      backgroundColor: "#FA943D",
                                      opacity: "10%",
                                    }}
                                  ></span>
                                  <Text
                                    color="#FA943D"
                                    style={{ position: "relative", zIndex: 1 }}
                                    size="14"
                                    fontWeight="700"
                                  >
                                    {data.label}
                                  </Text>
                                </div>
                              );
                            })}
                        </div>
                        {/* </AutoInputWord> */}
                      </div>
                    </Content>
                  </RightContent>
                </Content>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <LeftContent></LeftContent>
                <RightContent>
                  <Text style={{}} color="#FA943D">
                    *실제 이름/상품/정보 등 기준으로 byte 차이가 발생할 수
                    있습니다.
                  </Text>
                </RightContent>
              </div>
              <Spacer size={76.5} />
            </FormWrapper>
            <Content
              style={{
                display: "flex",
                flexDirection: "row",
                overflow: "visible",
              }}
            >
              <ActionButtonGroup>
                <CustomButton
                  background={"#7c7c7c"}
                  // onClick={handleChange}
                  text="취소"
                  type="button"
                  onClick={() => {
                    if (isModify) {
                      handleSetCancel();
                    } else {
                      closeSetAddModal();
                    }
                  }}
                />
                <Form.Item style={{ margin: "0px" }}>
                  <CustomButton
                    // height="100px"
                    background={"#fa6400"}
                    // htmlType="submit"
                    text={isModify ? "수정" : "완료"}
                    // disabled={selectedOption.label.length > 0 ? false : true}
                    onClick={onFinish}
                  />
                </Form.Item>
              </ActionButtonGroup>
            </Content>
            {isCustomerNonAttendanceModal ? (
              <NoticeModal
                multipleScreen={false}
                multipleButton={false}
                title="장기 미출석 (회원권)"
                headerIcon="/assets/images/icon/white/alert.png"
                firstButtonText="확인"
                primaryColor="#EB5252"
                body={
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Text>
                      <Text color="#000000">'장기 미출석 설정'</Text>
                      <Text color="#000000">은</Text>
                      <Text color="#EB5252"> 회원권 기준</Text>
                      <Text color="#000000">
                        으로, 수강권에는 적용되지 않습니다.
                      </Text>
                    </Text>
                  </div>
                }
                warningText="* 수강권 기준으로는 전송이 되지 않습니다."
                handleFirstButton={closeIsCustomerNonAttendanceModal}
              />
            ) : null}
            {isCreateCompleteModal && (
              <NoticeModal
                multipleScreen={false}
                multipleButton={false}
                title="자동알림 생성 완료"
                headerIcon="/assets/images/icon/white/v.png"
                primaryColor="#78B2A0"
                firstButtonText="확인"
                body={<Text color="#000">자동알림 생성이 완료되었습니다.</Text>}
                warningText="* 자동알림 수정 페이지에서 다시 수정 가능합니다."
                handleFirstButton={() => {
                  document.body.style.overflow = "unset";
                  setIsCreateCompleteModal(
                    (isCreateCompleteModal) => !isCreateCompleteModal
                  );
                  closeSetAddModal();
                  dispatch(
                    getAutoMessageSettingAction({
                      jgroup_key: jgroupKey,
                    })
                  );
                }}
              />
            )}
            {isModifyCompleteModal && (
              <NoticeModal
                multipleScreen={false}
                multipleButton={false}
                title="자동알림 수정 완료"
                headerIcon="/assets/images/icon/white/v.png"
                primaryColor="#78B2A0"
                firstButtonText="확인"
                body={<Text color="#000">자동알림 수정이 완료되었습니다.</Text>}
                warningText="* 자동알림 수정 페이지에서 다시 수정 가능합니다."
                handleFirstButton={() => {
                  document.body.style.overflow = "unset";
                  // setIsCreateCompleteModal(
                  //   (isCreateCompleteModal) => !isCreateCompleteModal
                  // );
                  setIsModifyCompleteModal(
                    (isModifyCompleteModal) => !isModifyCompleteModal
                  );
                  closeSetAddModal();
                  dispatch(
                    getAutoMessageSettingAction({
                      jgroup_key: jgroupKey,
                    })
                  );
                }}
              />
            )}
            {cancelModify && (
              <NoticeModal
                multipleScreen={false}
                multipleButton
                title="수정 취소"
                headerIcon="/assets/images/icon/white/alert.png"
                primaryColor="#F6CA4F"
                firstButtonText="아니오"
                secondButtonText="예"
                warningText="* 수정사항이 적용되지 않습니다."
                body={
                  <Text color="#000">
                    수정사항을 적용하지 않고 나가시겠습니까?
                  </Text>
                }
                handleFirstButton={handleSetCancel}
                handleSecondButton={() => {
                  document.body.style.overflow = "unset";
                  setIsCreateCompleteModal(
                    (isCreateCompleteModal) => !isCreateCompleteModal
                  );
                  closeSetAddModal();
                }}
              />
            )}
            {isInfoClicked && (
              <MessageInfoNoticeModal handleChange={openInfoModal} />
            )}
          </FormContainer>
        </Form>
      </PositionController>
    </ModalScreen>
  );
};

const antMoveDownIn = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const antMoveDownOut = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
`;

const ButtonStyle = `
flex-grow: 1;
border: none;
font-family: "Noto Sans KR";
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 29px;
text-align: center;
letter-spacing: -0.670707px;
:hover {
  cursor: pointer;
}
`;

const CustomSelect = styled.div`
  width: 329px;
  height: 45px;
  color: #a4a4a4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4a4a4a;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 12px;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  cursor: pointer;
`;

const SelectProductWrapper = styled.div`
  width: 100%;
  z-index: 10500;
  position: relative;
  height: 0;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: ${(props) =>
    props.enabled ? antMoveDownOut : antMoveDownIn};
  margin: 0px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const RightContent = styled.div`
  width: 681px;
  display: flex;
  align-items: center;
`;

const CloseIcon = styled.img`
  /* position: absolute; */
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  background: ${commonTheme.background.black4};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px;
  padding-bottom: 0;
  z-index: 10600;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

const RightHeader = styled.div`
  display: flex;
  align-items: center;
`;

const FormContainer = styled.div`
  position: relative;
  background: ${commonTheme.background.black4};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: 900px;
  height: 671px;
  /* overflow: auto; */
  /* padding: 30px; */
  /* padding-bottom: 0px; */
`;

const FormWrapper = styled.div`
  width: 100%;
  height: calc(100% - 125px);
  /* overflow: scroll; */
  overflow: ${(props) => (props.isSelectOption ? "hidden" : "scroll")};
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const CustomStyleBtn = styled.button`
  width: ${(props) => (props.width ? props.width : null)};
  height: ${(props) => (props.height ? props.height : null)};
  background: ${(props) => (props.background ? props.background : null)};
  border: ${(props) => (props.border ? props.border : "none")};
  border-color: ${(props) => (props.borderColor ? props.borderColor : "none")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};
  padding: ${(props) => (props.padding ? props.padding : null)};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const IsUse = styled.div`
  width: 115px;
  height: 33px;
  background: ${commonTheme.background.black7};
  border: none;
  border-radius: 3px;
  padding: 7px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
  color: ${commonTheme.font.black5};
  :hover {
    cursor: pointer;
  }
`;
export default MessageSettingModal;
