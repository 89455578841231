import axios from "axios";
import { connect } from "react-redux";

class Message {
  getCertificateData = async (data) => {
    const response = await axios.get(
      "https://nmsserver.broj.co.kr/massage/certificate?jgroup_key=109329"
    );
    return response.data.data;
  };
}

class Coupon {}

class ServerJSON {
  static instance = null;

  constructor() {
    this.BROJ_ALARM_RESOURSE_SERVER = null;
    this.BROJ_M_REDIRECT = null;
    this.BROJ_STATUS = null;
    this.BROJ_ALARM_RESOURSE_SERVER = null;
    this.BROJ_M_REDIRECT = null;
    this.BROJ_STATUS = null;
    this.BROJ_FACE_PRICE = null;
    this.BROJ_MAIL_SERVER = null;
    this.BROJ_PLANBOOK = null;
    this.BROJ_JNEWSFEED = null;
    this.BROJ_COMPANY = null;
    this.BROJ_BOOK = null;
    this.BROJ_M_SMS_REDIRECT = null;
    this.BROJ_MARKET = null;
    this.BROJ_RESOURSE_SERVER = null;
    this.BROJ_EMAIL_URI = null;
    this.BROJ_RESOURSE_OAUTH_SERVER = null;
    this.BROJ_BZONE = null;
    this.BROJ_GOLF_PRICE = null;
    this.BROJ_JPLANBOOK_SERVER = null;
    this.BROJ_CONTENTS = null;
    this.BROJ_HEALTH = null;
    this.BROJ_EMAIL = null;
    this.BROJ_CONTRACT = null;
    this.BROJ_BRAND = null;
    this.BROJ_SERVER = null;
    this.BROJ_WSOCKET_SERVER = null;
    this.BROJ_ALARM_SERVER = null;
    this.BROJ_SUPPORT_EMAIL = null;
    this.BROJ_MANUAL = null;
    this.BROJ_JOUATH_URL = null;
    this.BROJ_TZONE = null;
    this.BROJ_BUCKET = null;
    this.BROJ_KEY = null;
    this.BROJ_SMS_SERVER = null;
    this.BROJ_SURVEY = null;
    this.BROJ_TICKET_BASIC = null;
    this.BROJ_WEB = null;
    this.BROJ_TICKET = null;
    this.BROJ_SMS_NANO_SERVER = null;
    this.BROJ_MESSAGE_API_SERVER = null;
    this.BROJ_MARKET_SERVER = null;
    this.BROJ_OONTALK_SERVER = null;
    this.BROJ_PAY_SERVER = null;
    this.DOMAIN = null;
    this.LOGIN_PARAM = null;
  }

  static async init(config) {
    // console.log(config)
    let resourse_server = config.resourse_server;
    let domain = config.domain;
    let login_param = config.login_param;
    

    // // production
    // if (location.href.indexOf("broj.co.kr") != -1) {
    //   resourse_server = "https://brojserver.broj.co.kr/BroJServer/api/";
    //   domain = ".broj.co.kr";
    //   login_param =
    //     "?client_id=broj&client_secret=broj&redirect_uri=https://crm.broj.co.kr/&response_type=code&scope=me";
    // }
    // // stage or local
    // else {
    //   // stage
    //   if (location.href.indexOf("https") != -1) {
    //     resourse_server = "https://server.broj.shop/BroJServer/api/";
    //     domain = ".broj.shop";
    //     login_param =
    //       "?client_id=broj&client_secret=broj&redirect_uri=https://crm.broj.shop/&response_type=code&scope=me";
    //   }
    //   // local
    //   else {
    //     resourse_server = "https://server.broj.shop/BroJServer/api/";
    //     domain = ".broj.shop";
    //     login_param =
    //       "?client_id=broj&client_secret=broj&redirect_uri=https://crm.broj.shop/&response_type=code&scope=me";
    //     // resourse_server = "http://localhost:8080/api/";
    //   }
    // }

    if (resourse_server) {
      const { data } = await axios(resourse_server + "broj/json");
      ServerJSON.instance = {
        ...data,
        DOMAIN: domain,
        LOGIN_PARAM: login_param,
      };
    }

    return true;
  }

  static getInstance(): ServerJSON {
    if (ServerJSON.instance === null) {
      throw Error("브로제이 서버 관련 초기화가 실패했습니다");
      /**
       * 이 오류가 발생했다면 RNNetUtil.ServerJSON.init()을 앱 초기화 할 때 수행해야 한다!
       */
    }

    return ServerJSON.instance;
  }
}

export const Api = {
  Message,
  Coupon,
  ServerJSON,
};
