import moment from "moment";
import styled from "styled-components";
import MessageContentColumn from "./MessageTableContentColumn";

export const messageColumns = [
  {
    title: "No",
    dataIndex: "key",
    key: "key",
    width: 56,
    render: (key, record, index) => <CustomColumn>{index + 1}</CustomColumn>,
  },
  //   {
  //     title: "타입",
  //     dataIndex: "type",
  //     key: "type",
  //     width: 128,
  //     render: (key, record, index) => <CustomColumn>{record.TYPE}</CustomColumn>,
  //   },
  {
    title: "발신번호",
    dataIndex: "caller",
    key: "caller",
    width: 196,
    render: (_, record) => {
      if (record.TR_NUM) {
        return (
          <CustomColumn>
            {record?.TR_CALLBACK?.replace(
              /^(\d{3})(\d{4})(\d{4})$/,
              "$1-$2-$3"
            )}
          </CustomColumn>
        );
      }
      if (record.MSGKEY) {
        return (
          <CustomColumn>
            {record?.CALLBACK?.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3")}
          </CustomColumn>
        );
      }
      if (record.job_id) {
        return (
          <CustomColumn>
            {record?.callback_num?.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3")}
          </CustomColumn>
        );
      }
    },
  },
  {
    title: "수신번호",
    dataIndex: "receiver",
    key: "receiver",
    width: 196,
    render: (_, record, idx) => {
      if (record.TR_NUM) {
        return (
          <CustomColumn>
            {record?.TR_PHONE?.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3")}
          </CustomColumn>
        );
      }
      if (record.MSGKEY) {
        return (
          <CustomColumn>
            {record?.PHONE?.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3")}
          </CustomColumn>
        );
      }
      if (record.job_id) {
        return (
          <CustomColumn>
            {record?.rcpt_data?.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3")}
          </CustomColumn>
        );
      }
    },
  },
  {
    title: "날짜",
    dataIndex: "date",
    key: "date",
    width: 196,
    render: (_, record) => (
      <CustomColumn>
        {record.RSLTDATE ? record.RSLTDATE : record.TR_RSLTDATE ? record.TR_RSLTDATE : moment(record.submit_time).format("YYYY-MM-DD HH:mm:ss")}
      </CustomColumn>
    ),
  },
  {
    title: "결과",
    dataIndex: "result",
    key: "result",
    width: 132,
    render: (_, record) => {

      if (record.RSLT && record.RSLT === '0') {
        return  <CustomColumn>
          {"성공"}
        </CustomColumn>
      }

      if (record.RSLT && record.RSLT !== '0') {
        return  <CustomColumn>
          {`실패(${record.RSLT})`}
        </CustomColumn>
      }

      if (record.TR_RSLTSTAT && record.TR_RSLTSTAT === '0') {
        return  <CustomColumn>
          {"성공"}
        </CustomColumn>
      }

      if (record.TR_RSLTSTAT && record.TR_RSLTSTAT !== '0') {
        return  <CustomColumn>
          {`실패(${record.TR_RSLTSTAT})`}
        </CustomColumn>
      }   
      
      if (!record.is_reserved && record.result !== null && record.result === 0) {
        return  <CustomColumn>
          {'성공'}
        </CustomColumn>
      }

      if (!record.is_reserved && record.result !== null && record.result !== 0) {
        return  <CustomColumn>
          {`실패(${record.result})`}
        </CustomColumn>
      }

      if (record.is_reserved && record.result !== null && record.result === 0) {
        return  <CustomColumn>
          {'정상 예약'}
        </CustomColumn>
      }

      if (record.is_reserved && record.result !== null && record.result !== 0) {
        return  <CustomColumn>
          {`실패(${record.result})`}
        </CustomColumn>
      }
    
      return  <CustomColumn>
      {`알 수 없음`}
    </CustomColumn>
    }
    
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    width: 252,
    render: (_, record) => <MessageContentColumn record={record} />,
  },
];

export const pushNotificationColumn = [
  {
    title: "No",
    dataIndex: "key",
    key: "key",
    width: 56,
    render: (key, record, index) => <CustomColumn>{index + 1}</CustomColumn>,
  },
  //   {
  //     title: "타입",
  //     dataIndex: "type",
  //     key: "type",
  //     width: 128,
  //     render: (key, record, index) => <CustomColumn>{record.TYPE}</CustomColumn>,
  //   },

  {
    title: "고객명",
    dataIndex: "to_name",
    key: "to_name",
    width: 196,
    render: (_, record, idx) => (
      <CustomColumn>{record.to_name ? record.to_name : "-"}</CustomColumn>
    ),
  },
  {
    title: "날짜",
    dataIndex: "date",
    key: "date",
    width: 196,
    render: (_, record) => (
      <CustomColumn>
        {moment(record.created_dttm).format("YYYY-MM-DD HH:mm:ss")}
      </CustomColumn>
    ),
  },
  {
    title: "제목",
    dataIndex: "title",
    key: "title",
    width: 192,
    render: (_, record) => (
      <CustomColumn>{record.title ? record.title : "-"}</CustomColumn>
    ),
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    width: 252,
    render: (_, record) => <MessageContentColumn record={record} />,
  },
];

const commonColumnStyle = `
font-family: "Noto Sans KR-Regular";
font-style: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
justify-content: center;
text-align: center; `;

const CustomColumn = styled.div`
  ${commonColumnStyle}
`;
